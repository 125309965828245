





























































































































































































































































































































































































































import eventHub from "@/event-hub";
import {
    a11yFixBuefyModalAriaAttrs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";

import debounce from "lodash/debounce";

import { Component, Vue } from "vue-property-decorator";
import {
    AttendeeSearchResult,
    UpdateAppointmentPayload,
    AttendeeDataObject
} from "@/types/interfaces";
import { getModule } from "vuex-module-decorators";
import {
    isBefore,
    isAfter,
    startOfToday,
    eachHourOfInterval,
    addMinutes,
    roundToNearestMinutes,
    isValid,
    parseISO,
    isWithinInterval,
    startOfDay,
    endOfDay,
    isSameDay
} from "date-fns";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Ads from "@/components/shared/Ads.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
const attendeeSearchStore = getModule(attendeeSearchVuexModule);

import schedulingVuexModule from "@/store/vuex-modules/scheduling";
const schedulingStore = getModule(schedulingVuexModule);

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

// interface FuncTimeListOptions {
//     start: Date | number;
//     end: Date | number;
//     hourSegments: Array<number> | null;
// }

interface SelectTimeOption {
    value: Date;
    label: string;
}

@Component({
    components: {
        "vmp-ads": Ads,
        PageTitleHeader,
        Spinners
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    }
})
export default class CreateActivity extends Vue {
    componentHeaderLabel = "Request a Meeting";
    timeSelectNoOptionString = `Sorry, no available times.`;
    timeSelectPlaceholder = `Click to select...`;
    isLoadingAttendees = false;
    isLoadingInvitationStage = false;
    isSubmitting = false;
    submitSuccess = false;
    submitError = false;
    enableSeconds = false;
    minutesGranularity = 15;
    dateSelection: Date | null = null;
    searchSelection: null | AttendeeSearchResult = null;
    invitationStage: Array<AttendeeSearchResult | AttendeeDataObject> = [];
    resetPayload: UpdateAppointmentPayload = {
        userId: "",
        title: "",
        startTime: undefined,
        endTime: undefined,
        description: "",
        includeInCompanyProfile: false,
        includeInSchedule: false,
        invitations: []
    };
    updatePayload: UpdateAppointmentPayload = {
        userId: "",
        title: "",
        startTime: undefined,
        endTime: undefined,
        description: "",
        includeInCompanyProfile: false,
        includeInSchedule: false,
        invitations: []
    };
    timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    errorMessage = "";
    existingAppointmentId = "";
    invitationList: Array<string> = [];
    attendeeApiOptions = {
        id: ""
    };

    /**
     * Computed
     */
    get isProduction() {
        return this.$store.getters.isProduction;
    }

    get user() {
        return this.$store.getters.user;
    }

    get userInfo() {
        return this.$store.getters.userInfo;
    }

    get route() {
        return this.$store.state.route;
    }

    get exhibitorTier() {
        return parseInt(this.userInfo?.exhibitorTier);
    }

    get getConfigNavItem() {
        return this.$store.getters.getConfigNavItem;
    }

    get exhibitorCanAddToProfile() {
        const threshold = this.exhibitorThresholdAddToProfile;

        const condition1 = Boolean(
            this.exhibitorTier && threshold <= this.exhibitorTier
        );

        return condition1;
    }

    get invitationStageThreshold() {
        let returnValue = this.maxInvitesAttendee;
        const invitationThresholdsByTier = this.maxInvitesByTier || {};

        for (const key in invitationThresholdsByTier) {
            const tier = Number(key);
            const tierThreshold = invitationThresholdsByTier[key];
            if (tier <= this.exhibitorTier && Number(tierThreshold)) {
                returnValue = tierThreshold;
            }
        }

        return returnValue;
    }

    get hasExhibitorOptions() {
        const condition1 =
            this.exhibitorCanAddToProfile && 0 === this.invitationStage.length;

        // There could potentially be multiple exhibitor options to consider
        // e.g. condition1 || condition2 || condition3
        // etc...
        return condition1;
    }

    get allowedMeetingDates() {
        return this.$store.state.settingsVuexModule.allowedMeetingDates;
    }

    get allowedMeetingDateTimes() {
        return this.$store.state.settingsVuexModule.allowedMeetingDateTimes;
    }

    get maxInvitesAttendee() {
        return this.$store.state.settingsVuexModule.maxInvitesAttendee;
    }

    get maxInvitesByTier() {
        return this.$store.state.settingsVuexModule.maxInvitesByTier;
    }

    get exhibitorThresholdAddToProfile() {
        return this.$store.state.settingsVuexModule
            .exhibitorThresholdAddToProfile;
    }

    get selectableDates() {
        const allowedMeetingDates = this.allowedMeetingDates;
        return allowedMeetingDates
            .map((date: string) => {
                return parseISO(date);
            })
            .filter((date: Date) => {
                const today = startOfToday();
                return !isBefore(date, today);
            });
    }

    get earliestAllowedDateString() {
        return this.allowedMeetingDates[0];
    }

    get latestAllowedDateString() {
        const lastIndex = this.allowedMeetingDates.length - 1;
        return this.allowedMeetingDates[lastIndex];
    }

    get earliestAllowedDate() {
        const dateString = this.earliestAllowedDateString;
        let returnValue = null;
        let date;
        const today = startOfToday();

        if (dateString) {
            date = parseISO(dateString);

            if (isValid(date)) {
                if (Vue.prototype.MgIsToday(date) || isBefore(date, today)) {
                    returnValue = today;
                } else {
                    returnValue = date;
                }
            }
        }

        return returnValue;
    }

    get latestAllowedDate() {
        const dateString = this.latestAllowedDateString;
        let returnValue = null;
        let date;

        if (dateString) {
            date = parseISO(dateString);

            if (isValid(date)) {
                returnValue = date;
            }
        }
        return returnValue;
    }

    get isReadyForSubmit() {
        const title = this.updatePayload.title;
        const date = this.dateSelection instanceof Date;
        const startTime = this.updatePayload.startTime instanceof Date;
        const endTime = this.updatePayload.endTime instanceof Date;
        const coreRequirements = Boolean(title && date && startTime && endTime);

        let returnValue;

        // Inivitations are required unless exhibitor is adding to profile
        if (this.updatePayload.includeInCompanyProfile) {
            returnValue = coreRequirements;
        } else {
            returnValue =
                coreRequirements && Boolean(this.invitationStage.length);
        }

        return returnValue;
    }

    get attendeeSearchResults() {
        return attendeeSearchStore.attendeeSearchResults;
    }

    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get pageOptions() {
        const returnValue = this.$store.getters.getPageOptions(
            "createActivity"
        );

        return returnValue;
    }

    get textHeader() {
        return this.pageOptions.textHeader;
    }

    get attendeeData() {
        return attendeeStore.attendee;
    }

    get timeList() {
        const dateSelected = this.dateSelection;
        let allHours = null;
        let start = null;
        let end = null;
        let returnValue: Array<SelectTimeOption> = [];

        if (!dateSelected) {
            return returnValue;
        }

        start = startOfDay(dateSelected);
        end = endOfDay(dateSelected);

        allHours = eachHourOfInterval({
            start: start,
            end: end
        });
        returnValue = allHours.flatMap((date: Date) => {
            const hourSegments = [0, 15, 30, 45];
            const hourOptions = hourSegments.map((segment: number) => {
                const adjustedDate = addMinutes(date, segment);
                const iso = adjustedDate.toISOString();
                const option = {
                    value: adjustedDate,
                    label: Vue.prototype.MgFormatISODateTime(iso, "time")
                };

                return option;
            });

            return hourOptions;
        });

        return returnValue;
    }

    get selectableStartTimes() {
        const dateSelected = this.dateSelection;
        const allowedTimes = this.allowedMeetingDateTimes || [];
        const allowedTimesForSelectedDate = allowedTimes.filter(
            (dateSet: Array<string>) => {
                const firstIsoDate = dateSet[0];
                const secondIsoDate = dateSet[1];
                let returnValue = false;

                if (
                    dateSelected &&
                    Vue.prototype.MgIsValidISOString(firstIsoDate) &&
                    Vue.prototype.MgIsValidISOString(secondIsoDate)
                ) {
                    const firstDate = new Date(firstIsoDate);
                    const secondDate = new Date(secondIsoDate);
                    const sameDay = isSameDay(dateSelected, firstDate);

                    let withinInterval = false;
                    if (isBefore(firstDate, secondDate)) {
                        withinInterval = isWithinInterval(dateSelected, {
                            start: firstDate,
                            end: secondDate
                        });
                    }

                    returnValue = sameDay || withinInterval;
                }

                return returnValue;
            }
        );

        let returnValue: Array<SelectTimeOption> = [];

        if (Array.isArray(allowedTimesForSelectedDate)) {
            allowedTimesForSelectedDate.forEach((dateSet: Array<string>) => {
                const firstIsoDate = dateSet[0];
                const secondIsoDate = dateSet[1];

                if (
                    Vue.prototype.MgIsValidISOString(firstIsoDate) &&
                    Vue.prototype.MgIsValidISOString(secondIsoDate)
                ) {
                    const firstDate = new Date(firstIsoDate);
                    const secondDate = new Date(secondIsoDate);
                    const matches = this.timeList.filter(
                        (item: SelectTimeOption) => {
                            const date = item.value;
                            let withinInterval = false;
                            if (isBefore(firstDate, secondDate)) {
                                withinInterval = isWithinInterval(date, {
                                    start: firstDate,
                                    end: secondDate
                                });
                            }
                            return withinInterval;
                        }
                    );

                    returnValue = [...returnValue, ...matches];
                }
            });

            // remove duplicates
            returnValue = Array.from(new Set(returnValue));
        } else {
            console.error("allowedTimesForSelectedDate is not an array");
        }

        return returnValue;
    }

    get selectableEndTimes() {
        const returnValue = this.selectableStartTimes.filter(
            (option: SelectTimeOption) => {
                const selectedStartTime = this.updatePayload.startTime;
                const date = option.value;
                if (selectedStartTime) {
                    return isAfter(date, selectedStartTime);
                } else {
                    return true;
                }
            }
        );
        return returnValue;
    }

    /**
     * Watchers
     *
     * NOTE:
     * This component has pre-population.
     * do not @Watch anything on create - wait until after pre-population happens.
     * To do that add all watchers in the `handleWatchers` section of this component.
     */

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        this.prepopulate();
        this.handleWatchers();
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        this.resetForm();
    }

    /**
     * Methods
     */
    // getTimeList(
    //     options: FuncTimeListOptions = {
    //         start: 0,
    //         end: 0,
    //         hourSegments: null
    //     }
    // ) {
    //     const allHours = eachHourOfInterval({
    //         start: options.start,
    //         end: options.end
    //     });
    //     const hourSegments = options.hourSegments || [0, 15, 30, 45];
    //     let returnValue: Array<SelectTimeOption> = [];

    //     if (options.start && options.end) {
    //         returnValue = allHours.flatMap((date: Date) => {
    //             const hourOptions = hourSegments.map((segment: number) => {
    //                 const adjustedDate = addMinutes(date, segment);
    //                 const iso = adjustedDate.toISOString();
    //                 const option = {
    //                     value: adjustedDate,
    //                     label: Vue.prototype.MgFormatISODateTime(iso, "time")
    //                 };

    //                 return option;
    //             });

    //             return hourOptions;
    //         });
    //     }

    //     return returnValue;
    // }

    handleWatchers() {
        this.$watch("dateSelection", this.handleDateSelectChange);
        this.$watch("invitationStage", this.handleInvitationStageChange);
        this.$watch(
            "updatePayload.startTime",
            this.handlePayloadStartTimeChanged
        );
        this.$watch(
            "updatePayload.invitations",
            this.handlePayloadInvitationsChanged
        );
        this.$watch("searchSelection", this.handleSearchSelectionChanged);
    }

    handlePayloadInvitationsChanged() {
        const invitations = this.updatePayload.invitations;
        if (Array.isArray(invitations) && invitations.length) {
            this.updatePayload.includeInCompanyProfile = false;
        }
    }

    handleDateSelectChange() {
        if (this.dateSelection && Vue.prototype.MgIsToday(this.dateSelection)) {
            const minutesGranularity = this.minutesGranularity;
            const futureTime = addMinutes(new Date(), minutesGranularity);
            const startTime = roundToNearestMinutes(futureTime, {
                nearestTo: minutesGranularity
            });

            this.updatePayload.startTime = startTime;
        }
    }

    handleInvitationStageChange() {
        this.updatePayload.invitations = this.invitationStage
            .map((item) => item.attendeeId || "")
            .filter((id) => id);
    }

    handlePayloadStartTimeChanged() {
        let date;

        if (this.updatePayload?.startTime instanceof Date) {
            date = this.updatePayload.startTime;

            const minutesToAdd = this.minutesGranularity * 2;
            this.updatePayload.endTime = addMinutes(date, minutesToAdd);
        }
    }

    handleSearchSelectionChanged() {
        if (this.searchSelection && "string" !== typeof this.searchSelection) {
            const myId = this.user.id;
            const selectionIsMe = Boolean(
                myId === this.searchSelection.attendeeId
            );
            const invitationRecord = this.invitationStage.find((item) => {
                return (
                    item.attendeeId ===
                    (this.searchSelection
                        ? this.searchSelection.attendeeId
                        : "")
                );
            });

            if (!invitationRecord && !selectionIsMe) {
                this.invitationStage.push(this.searchSelection);
            }
        }
    }

    prepopulate() {
        const componentHeaderLabel = this.pageOptions?.componentHeaderLabel;
        if (componentHeaderLabel) {
            this.componentHeaderLabel = componentHeaderLabel;
        }

        this.handleLoadAttendee();
        this.handleLoadExistingAppointment();
    }

    handleLoadAttendee() {
        let attendee: AttendeeDataObject = {};

        if (this.route?.params?.attendee) {
            attendee = JSON.parse(this.route.params.attendee);
        } else if ("attendeeProfile" === this.route?.from?.name) {
            attendee = JSON.parse(JSON.stringify(this.attendeeData));
        }

        if (attendee?.attendeeId) {
            this.invitationStage.push(attendee);
            this.handleInvitationStageChange();
        }
    }

    handleLoadExistingAppointment() {
        const appointmentParam = this.route?.params?.appointment;
        let existingAppointments;

        if (appointmentParam) {
            existingAppointments = JSON.parse(appointmentParam);
        }

        if (
            !existingAppointments ||
            (existingAppointments && !existingAppointments.id)
        ) {
            return;
        }

        this.componentHeaderLabel = "Edit Appointment";

        this.existingAppointmentId = existingAppointments.id;

        this.updatePayload.userId = existingAppointments.attendeeId;
        this.updatePayload.title = existingAppointments.title;

        this.dateSelection = new Date(existingAppointments.startTime);

        this.updatePayload.startTime = new Date(existingAppointments.startTime);

        this.updatePayload.endTime = new Date(existingAppointments.endTime);
        this.updatePayload.description = existingAppointments.description;

        this.invitationList = [
            ...existingAppointments.networking.declined,
            ...existingAppointments.networking.pending,
            ...existingAppointments.networking.confirmed
        ];

        const filteredInvitationList = this.invitationList.filter(
            (item: string) => item != this.user.id
        );

        this.isLoadingInvitationStage = true;
        attendeeStore
            .getAttendees(filteredInvitationList)
            .then((response) => {
                const x = response as Array<AttendeeDataObject>;
                x.forEach((element: AttendeeDataObject | Error) => {
                    if (!(element instanceof Error)) {
                        // this.invitationStage = [...this.invitationStage, res]
                        this.invitationStage.push(element);
                    }
                });
            })
            .finally(() => {
                this.isLoadingInvitationStage = false;
            });
    }

    resetForm() {
        this.updatePayload = JSON.parse(JSON.stringify(this.resetPayload));
        this.dateSelection = null;
        this.invitationStage = [];
    }

    fetchAttendees = debounce((keyword: string) => {
        const searchInput = {
            allFields: keyword
        };
        attendeeSearchStore.searchAttendees(searchInput).finally(() => {
            // Not sure why we can't update component data here
            // so using `resetFlags` instead.
            this.resetFlags();
        });
    }, 500);

    resetFlags() {
        this.isLoadingAttendees = false;
    }

    handleTyping(keyword: string) {
        this.isLoadingAttendees = true;
        this.fetchAttendees(keyword);
    }

    isPreviouslyInvited(id: string) {
        if (this.invitationList.length) {
            return this.invitationList.includes(id);
        }
    }

    removeAttendeeSelection(attendeeId: string) {
        if (this.isPreviouslyInvited(attendeeId)) {
            return;
        }

        const index = this.invitationStage.findIndex(
            (item) => attendeeId === item.attendeeId
        );
        if (-1 < index) {
            this.invitationStage.splice(index, 1);
        }
    }

    udpateTimesWithDate() {
        if (!(this.dateSelection instanceof Date)) return;

        const month = this.dateSelection.getMonth();
        const day = this.dateSelection.getDate();
        const year = this.dateSelection.getFullYear();

        if (this.updatePayload.startTime) {
            this.updatePayload.startTime.setMonth(month);
            this.updatePayload.startTime.setDate(day);
            this.updatePayload.startTime.setFullYear(year);
        }

        if (this.updatePayload.endTime) {
            this.updatePayload.endTime.setMonth(month);
            this.updatePayload.endTime.setDate(day);
            this.updatePayload.endTime.setFullYear(year);
        }
    }

    handleSubmitSuccess() {
        const scheduleNavItem = this.getConfigNavItem("Schedule");
        const sessionsNavItem = this.getConfigNavItem("Sessions");
        let redirectTo = "/";

        if (scheduleNavItem && scheduleNavItem.active) {
            redirectTo = scheduleNavItem.path;
        } else if (sessionsNavItem && sessionsNavItem.active) {
            redirectTo = sessionsNavItem.path;
        }

        this.resetForm();

        this.$router.push(redirectTo);
    }

    resetSubmitFlags() {
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.submitError = false;
    }

    setErrorMessage(message: string | undefined) {
        if (message) {
            this.errorMessage = message;
        }
    }

    submitForm() {
        const appointmentParam = this.route?.params?.appointment;
        let payload = null;

        this.isSubmitting = true;
        this.updatePayload.userId = this.user?.id;
        // this.udpateTimesWithDate();

        payload = JSON.parse(JSON.stringify(this.updatePayload));

        if (appointmentParam) {
            payload.exAppId = this.existingAppointmentId;
            schedulingStore
                .editAppointment(payload)
                .then(() => (this.submitSuccess = true))
                .catch((error) => {
                    const data = error?.response?.data;

                    this.setErrorMessage(data);
                    this.submitError = true;
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        } else {
            schedulingStore
                .postAppointment(payload)
                .then(() => {
                    this.submitSuccess = true;
                })
                .catch((error) => {
                    const data = error?.response?.data;

                    this.setErrorMessage(data);
                    this.submitError = true;
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        }
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }

    handleBuefyNotificationFixes(el: HTMLElement) {
        a11yFixBuefyNotificationAriaAttrs(el);
    }
}
